import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { SiteLayout, Head, Holder, Intro } from '~/components';
import * as cases from '../cases.module.scss';
import * as ems from './ems.module.scss';

const CasesEmsPage = () => (
  <SiteLayout>
    <Head title="クランプ据置式スマートメーターによる「電力見える化」システム" />

    <StaticImage
      alt=""
      src="./images/hero_ems.jpg"
      quality={100}
      className={cases.introImg}
    />

    <Intro>
      <Holder
        tag="自社製品の電力メータを活用した電力見える化サービス"
        phrase="電力の見える化で、<br />電力削減と最適・快適な利用環境を"
        title="EMS"
        description="会津ラボのEMSは、電力を見える化することでピーク時の消費電力を抑えるピークカットや、電気料金の安い時間帯に稼働させるピークシフトを実現します。<br />メータにはスマートプラグ型とクランプ式据置型があり、家庭用電化製品から工業用三相三線までの電力計測が可能です。<br />家庭用のHEMS、工場・施設用のFEMSを導入することで、5%~30%程度の削減効果が期待でき、最適・快適な利用環境の維持に役立ちます。"
        type="自社サービス事業"
      />
    </Intro>

    <section className={cases.sectionBlur}>
      <div className={cases.inner}>
        <h2>FEMS 電力見える化システム</h2>
        <p>工場内にクランプ式据置型を設置することで、エネルギーの利用状況を見える化します。<br />
        階層毎にマップやリスト、グラフ等最適な画面で表示し、すべての電力量をネットワークを介してリアルタイムで、いつ（時間）・どこで（場所）・何に（施設）・どれくらい（量）電力を使っているか確認できるので、エネルギーの無駄を「見える化」し、運用の合理化・省力化による安定創業や運用コストの削減、環境意識の向上をサポートします。<br />
        他にも管理値設定によるアラート金額、CO2のレート表示、データのファイル出力による原動費管理が可能です。運用成果を把握し、検討・改善することで、5%~30%程度の削減効果が期待できます。<br />
        また弊社では、省エネ診断士と協力して導入検討企業様、導入企業様への省エネ診断サービスも実施しております。<br />
        お気軽にお問い合わせください。</p>

        <div className={ems.femsImages}>
          <StaticImage
            alt=""
            src="./images/img_clump.png"
            quality={80}
            className={ems.clump}
          />
          <StaticImage
            alt=""
            src="./images/img_electric.png"
            quality={100}
            className={ems.electric}
          />
        </div>

        <h2>メーターの特徴</h2>
        <div className={ems.meterFeature}>
          <StaticImage
            alt=""
            src="./images/img_feature01.png"
            quality={80}
            className={ems.featureImage}
          />
          <StaticImage
            alt=""
            src="./images/img_feature02.png"
            quality={80}
            className={ems.featureImage}
          />
          <StaticImage
            alt=""
            src="./images/img_feature03.png"
            quality={80}
            className={ems.featureImage}
          />
        </div>
      </div>
    </section>

    <section className={`${cases.section} ${ems.distance}`}>
      <div className={cases.inner}>
        <div className={ems.hemsLayout}>
          <div className={ems.sectionBox}>
            <h2>HEMS SmartPlug</h2>
            <p>簡易設置可能な家庭向け多機能スマートプラグによって、電力だけでなく環境データの計測をおこないます。また赤外線リモコン機能を内蔵しており、Smart Plug専用のアプリケーションでは、計測したデータを個別のグラフやリアルタイムで確認ができるほか、電源を操作するリレーボタンやエアコン・照明の操作も可能です。<br />
        本製品はブロックチェーンを活用した電力取引サービスの実証事業において製作され、アプリケーションを含めた実用化のテストを終えています。<br />
        現在個人向けには販売しておりませんが、企業向け開発案件では受託可能です。詳細についてはお気軽にお問い合わせください。</p>
          </div>

          <div className={ems.smartplug}>
            <StaticImage
              alt=""
              src="./images/img_smartplug.png"
              quality={100}
            />
          </div>
        </div>

        <div className={ems.hemsAppImages}>
          <StaticImage
            alt=""
            src="./images/img_app01_ems.png"
            quality={100}
            className={ems.hemsAppImage}
          />
          <StaticImage
            alt=""
            src="./images/img_app02_ems.png"
            quality={100}
            className={ems.hemsAppImage}
          />
          <StaticImage
            alt=""
            src="./images/img_app03_ems.png"
            quality={100}
            className={ems.hemsAppImage}
          />
        </div>

        <h2 className={ems.sectionTitle}>利用シーン</h2>
        <div className={ems.useCaseImages}>
          <StaticImage
            alt=""
            src="./images/img_usecase01.png"
            quality={80}
            className={ems.useCaseImage}
          />
          <StaticImage
            alt=""
            src="./images/img_usecase02.png"
            quality={80}
            className={ems.useCaseImage}
          />
          <StaticImage
            alt=""
            src="./images/img_usecase03.png"
            quality={80}
            className={ems.useCaseImage}
          />
        </div>
      </div>
    </section>

  </SiteLayout>
);

export default CasesEmsPage;
